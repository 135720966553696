import React from 'react';
import logo from './logo.svg';
import './App.css';
import HomeScreen from './screens/Home';

function App() {
  return (
    <>
      <HomeScreen />
    </>
  );
}

export default App;
