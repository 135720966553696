// src/screens/HomeScreen.tsx
import React from 'react';
import Header from '../../components/Shared/Header';
import Footer from '../../components/Shared/Footer';
import DocumentationPreview from '../../components/Home/DocumentationPreview';
import { Container } from '@mui/material';

const HomeScreen: React.FC = () => {
  return (
    <Container>
      <Header />
      <main>
        <DocumentationPreview />
      </main>
      <Footer />
    </Container>
  );
};

export default HomeScreen;