// src/components/DocumentationPreview.tsx
import React from 'react';

const DocumentationPreview: React.FC = () => {
  return (
    <div>
      <h2>Documentation Preview</h2>
      <p>Here you can see a preview of the documentation generated from your zip file.</p>
      {/* Add more functionality as needed */}
    </div>
  );
};

export default DocumentationPreview;